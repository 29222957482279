import apiClient from 'services/axios'
import APIs from './constants'

class AuthService {
  constructor() {
    this.client = apiClient
  }

  login = async ({ body }) => {
    return this.client.post(APIs.adminLogin, body)
  }

  currentAccount = async () => {
    return this.client.get(APIs.profile)
  }

  updateProfile = async ({ body }) => {
    return this.client.patch(APIs.updateMyProfile, body)
  }
}

export default AuthService
