import * as qs from 'querystring'
import apiClient from 'services/axios'
import APIs from './constants'

class RefundService {
  constructor() {
    this.client = apiClient
  }

  //   createPublication = async ({ body }) => {
  //     return this.client.post(`${APIs.PUBLICATION}`, body)
  //   }

  getAll = async ({ query }) => {
    console.log('---->', APIs.REFUNDS_LIST)
    return this.client.get(`admin/refunds?${qs.stringify(query)}`)
  }

  //   getSingle = async ({ path }) => {
  //     return this.client.get(`${APIs.SINGLE_PUBLICATION.replace(':slug', path.slug)}`)
  //   }

  //   updatePublication = async ({ body, path }) => {
  //     return this.client.patch(`${APIs.SINGLE_PUBLICATION.replace(':slug', path.slug)}`, body)
  //   }
}

export default RefundService
