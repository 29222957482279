import { notification } from 'antd'
import axios from 'axios'
import store from 'store'

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
})

apiClient.interceptors.request.use((request) => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

apiClient.interceptors.response.use(undefined, (error) => {
  const { response } = error
  if (!response) {
    notification.error({
      message: 'Network Error',
    })
  }

  throw error
})

export default apiClient
