import React from 'react'
import { connect } from 'react-redux'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({ settings })

const Footer = () =>
  // { settings: { logo } }
  {
    return (
      <div className={style.footer}>
        <div className={style.footerInner}>
          <strong className="mr-2">HUBSCRIBE</strong>
          {/* </a> */}
          <br />
          <p className="mb-0">Copyright © {new Date().getFullYear()} </p>
        </div>
      </div>
    )
  }

export default connect(mapStateToProps)(Footer)
