import * as qs from 'querystring'
import apiClient from 'services/axios'
import APIs from './constants'

class PublicationService {
  constructor() {
    this.client = apiClient
  }

  createPublication = async ({ body }) => {
    return this.client.post(`${APIs.PUBLICATIONS}`, body)
  }

  getAll = async ({ query }) => {
    return this.client.get(`${APIs.PUBLICATIONS}?${qs.stringify(query)}`)
  }

  getTrendingPublications = async () => {
    // console.log(query)
    return this.client.get(`${APIs.TRENDING_PUBLICATIONS}`)
  }

  getSingle = async ({ path }) => {
    return this.client.get(`${APIs.SINGLE_PUBLICATION.replace(':id', path?.id)}`)
  }

  updatePublication = async ({ body, path }) => {
    return this.client.patch(`${APIs.SINGLE_PUBLICATION.replace(':slug', path?.slug)}`, body)
  }

  performActionPublication = async ({ body, path }) => {
    return this.client.post(`${APIs.PERFORM_ACTION_PUBLICATION.replace(':id', path?.id)}`, body)
  }
}

export default PublicationService
