// import { all, takeEvery, put } from 'redux-saga/effects'
import { all, takeEvery, put } from 'redux-saga/effects'

// import { notification } from 'antd'
// import { history } from 'index'
import { doApiCall } from 'redux/helpers'
import AnalyticsService from 'services/analytics'
// import APP from 'routes'
import { analyticsData } from 'constantData'
import actions from './actions'

const service = new AnalyticsService()

export function* FETCH_SUBSCRIPTIONS({ payload }) {
  const response = yield doApiCall(service.getAnalyticsPublications, payload, actions.SET_STATE)
  // console.log(payload)

  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { subscriptions: response.data },
    })
  }
}
export function* FETCH_POST_ANALYTICS({ payload: { path, query } }) {
  const response = yield doApiCall(service.getAnalyticsPosts, { path, query }, actions.SET_STATE)
  // console.log(payload)
  const dataKey = analyticsData[path.alias]
  const load = {}
  load[dataKey] = response.data
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: load,
    })
  }
}

export function* FETCH_REVENUE_ANALYTICS({ payload }) {
  const response = yield doApiCall(service.getAnalyticsRevenue, payload, actions.SET_STATE)
  // console.log(payload)
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { revenue: response.data },
    })
  }
}
export function* FETCH_LAST_MONTH_STATS({ payload }) {
  const response = yield doApiCall(
    service.getAnalyticsDashbordStatstics,
    payload,
    actions.SET_STATE,
  )
  // console.log(payload)
  console.log('=========>', response)
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { lastMonthStats: response.data },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_POST_ANALYTICS, FETCH_POST_ANALYTICS)])
  yield all([takeEvery(actions.FETCH_LAST_MONTH_STATS, FETCH_LAST_MONTH_STATS)])
  yield all([takeEvery(actions.FETCH_REVENUE_ANALYTICS, FETCH_REVENUE_ANALYTICS)])
  yield all([takeEvery(actions.FETCH_SUBSCRIPTIONS, FETCH_SUBSCRIPTIONS)])
}
