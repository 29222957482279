import { all, takeEvery, put } from 'redux-saga/effects'
import { notification } from 'antd'
// import { history } from 'index'
import { doApiCall } from 'redux/helpers'
import AdminSettingsService from 'services/adminSettings'
import actions from './actions'

const service = new AdminSettingsService()

export function* POST_SETTINGS({ payload }) {
  const { body, notificationType = {} } = payload
  const response = yield doApiCall(service.postAdminSettings, { body }, actions.SET_STATE)
  console.log('TEST', response)
  if (response.success) {
    if (notificationType.delete) {
      yield notification.info({
        message: 'FAQ deleted.',
      })
    } else {
      yield notification.success({
        message: 'Updated Successfully',
      })
    }
    const load = {}

    load[body.key] = response.data

    yield put({
      type: actions.SET_STATE,
      payload: load,
    })
    // yield history.push(APP.USERS_WRITERS_LIST)
  }
}
// export function* FETCH_PUBLICATIONS({ payload: { query } }) {
//   const response = yield doApiCall(service.getAll, { query }, actions.SET_STATE)

//   if (response.success) {
//     yield put({
//       type: actions.SET_STATE,
//       payload: { list: response.data, pagination: response.meta.pagination },
//     })
//   }
// }
export function* FETCH_SINGLE({ payload: { path } }) {
  const response = yield doApiCall(service.getAdminSettings, { path }, actions.SET_STATE)
  const payload = {}
  payload[path.key] = response.data
  //   console.log('---0--0-0-0----', payload)
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload,
    })
  }
}
// export function* UPDATE_PUBLICATION({ payload }) {
//   const { body, path } = payload
//   console.log(path)
//   const response = yield doApiCall(service.updatePublication, { body, path }, actions.SET_STATE)
//   console.log('TEST', response)
//   if (response.success) {
//     yield notification.success({
//       message: response.data.message,
//       // description: response.data.message,
//     })
//     yield put({
//       type: actions.FETCH_SINGLE,
//       payload: { path: response.data.slug },
//     }) // yield put({
//     //   type: actions.SET_STATE,
//     //   payload: {
//     //     details: response.data,
//     //     loading: false,
//     //     authorized: true,
//     //   },
//     // })
//     yield history.push(APP.USERS_WRITERS_LIST)
//   }
// }
// export function* PERFORM_ACTION_PUBLICATION({ payload: { path, body } }) {
//   console.log('<=========>', path)
//   const response = yield doApiCall(
//     service.performActionPublication,
//     { path, body },
//     actions.SET_STATE,
//   )
//   const action = body.action === 'block' ? 'Blocked' : 'Published'

//   if (response.success) {
//     yield notification.success({
//       message: `Publication successfully ${action}`,
//       // description: response.data.message,
//     })
//     yield put({
//       type: actions.FETCH_SINGLE,
//       payload: { path: { slug: path.slug } },
//     })
//   }
// }
export default function* rootSaga() {
  yield all([takeEvery(actions.POST_SETTINGS, POST_SETTINGS)])
  yield all([takeEvery(actions.FETCH_SINGLE, FETCH_SINGLE)])
  //   yield all([takeEvery(actions.UPDATE_PUBLICATION, UPDATE_PUBLICATION)])
  //   yield all([takeEvery(actions.PERFORM_ACTION_PUBLICATION, PERFORM_ACTION_PUBLICATION)])
  //   yield all([takeEvery(actions.FETCH_SINGLE, FETCH_SINGLE)])

  //   yield all([takeEvery(actions.PERFORM_ACTION_READER, PERFORM_ACTION_READER)])
}
