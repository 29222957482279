import * as qs from 'querystring'
import apiClient from 'services/axios'
import APIs from './constants'

class LedgerService {
  constructor() {
    this.client = apiClient
  }

  //   createPublication = async ({ body }) => {
  //     return this.client.post(`${APIs.PUBLICATION}`, body)
  //   }

  getAllLedger = async ({ query }) => {
    return this.client.get(`${APIs.LEDGER_LIST}?${qs.stringify(query)}`)
  }

  getAllLedgerItems = async ({ query, path }) => {
    return this.client.get(
      `${APIs.LEDGER_ITEMS_LIST.replace(':id', path?.id)}?${qs.stringify(query)}`,
    )
  }

  getSingleLedger = async ({ path }) => {
    return this.client.get(`${APIs.SINGLE_LEDGER.replace(':id', path?.id)}`)
  }

  retryLedgerSettlement = async ({ path }) => {
    return this.client.post(`${APIs.RETRY_SETTLEMENT.replace(':id', path?.id)}`)
  }

  getSingleLedgerItem = async ({ path }) => {
    return this.client.get(
      `${APIs.SINGLE_LEDGER_ITEM.replace(
        ':ledgerId/ledger-items/:ledgerItemId',
        `${path?.ledgerId}/ledger-items/${path.ledgerItemId}`,
      )}`,
    )
  }
}

export default LedgerService
