import { history } from 'index'
import { React, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import APP from 'routes'

const Authorizer = ({ Component, userScope, requiredScope, ...state }) => {
  const [userAuthorized, setUserAuthorized] = useState(true)
  useEffect(() => {
    if (requiredScope && requiredScope.length) {
      for (let i = 0; i < requiredScope.length; i += 1) {
        if (userScope && !userScope?.includes(requiredScope[i])) {
          setUserAuthorized(null)
          history.push(APP.NOT_FOUND.path)
        }
      }
    }
  }, [userScope])
  return (
    <>
      {userAuthorized ? (
        <div>
          <Component {...state} />
        </div>
      ) : null}
    </>
  )
}

const mapStateToProps = ({ user }) => ({
  userScope: user.scope,
  authorized: user.authorized,
})

export default connect(mapStateToProps)(Authorizer)
