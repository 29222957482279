export default {
  SET_STATE: 'users/writers/SET_STATE',
  FETCH_WRITERS: 'users/writers/FETCH_WRITERS',
  FETCH_SINGLE_WRITER: 'users/writers/FETCH_SINGLE_WRITER',
  UPDATE_WRITER: 'users/writers/UPDATE_WRITER',
  CREATE_WRITER: 'users/writers/CREATE_WRITER',
  PERFORM_ACTION_WRITER: 'users/writers/PERFORM_ACTION_WRITER',
  FETCH_TOP_WRITERS: 'users/writers/FETCH_TOP_WRITERS',
  FETCH_SINGLE_FUND_ACCOUNT: 'users/writers/FETCH_SINGLE_FUND_ACCOUNT',
}
