// import { all, takeEvery, put } from 'redux-saga/effects'
import { all, takeEvery, put } from 'redux-saga/effects'
// import { history } from 'index'
import { doApiCall } from 'redux/helpers'
import CommentService from 'services/comment'
import { notification } from 'antd'

// import APP from 'routes'
import actions from './actions'

const commentService = new CommentService()

export function* FETCH_COMMENTS({ payload: { query } }) {
  const response = yield doApiCall(commentService.getAllComments, { query }, actions.SET_STATE)

  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { list: response.data, pagination: response.meta.pagination },
    })
  }
}
export function* DELETE_COMMENT({ payload: { path, query } }) {
  const response = yield doApiCall(commentService.deleteComment, { path }, actions.SET_STATE)
  yield notification.success({
    message: 'Comment Deleted',
    description: 'You have deleted comment successfully!',
  })
  yield put({
    type: actions.FETCH_COMMENTS,
    payload: { query },
  })
  console.log(response)
}
export default function* rootSaga() {
  //   yield all([takeEvery(actions.CREATE_PUBLICATION, CREATE_PUBLICATION)])
  yield all([takeEvery(actions.DELETE_COMMENT, DELETE_COMMENT)])
  yield all([takeEvery(actions.FETCH_COMMENTS, FETCH_COMMENTS)])

  //   yield all([takeEvery(actions.PERFORM_ACTION_READER, PERFORM_ACTION_READER)])
}
