import actions from './actions'

const initialState = {
  loading: false,
  detail: {},
  formErrors: {},
  privacyPolicy: {},
  contentGuidelines: {},
  copyrightRepeatInfringementPolicy: {},
  copyrightDisputePolicy: {},
  publisherAgreement: {},
  termsOfUse: {},
  ccpaPolicy: {},
  generalFaq: {},
  readerFaq: {},
  writerFaq: {},
}

export default function adminSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
