import * as qs from 'querystring'
import apiClient from 'services/axios'
import APIs from './constants'

class ActivityLogsService {
  constructor() {
    this.client = apiClient
  }

  getAll = async ({ query }) => {
    return this.client.get(`${APIs.ACTIVITY_LOGS}?${qs.stringify(query)}`)
  }
}

export default ActivityLogsService
