const APP = {
  DASHBOARD: { path: '/dashboard', requiredScope: [] },
  ADMIN_LOGIN: { path: '/auth/login', requiredScope: [] },
  USERS_WRITERS_LIST: { path: '/users/writers', requiredScope: ['users:read'] },
  USERS_READERS_LIST: { path: '/users/readers', requiredScope: ['users:read'] },
  USERS_WRITERS_EDIT: { path: '/users/writers/:id', requiredScope: ['users:read'] },
  USERS_READERS_EDIT: { path: '/users/readers/:id', requiredScope: ['users:read'] },
  USERS_ADMIN_LIST: { path: '/users/admins', requiredScope: ['users:read'] },
  USERS_ADMIN_EDIT: { path: '/users/admins/:id', requiredScope: ['users:create', 'users:update'] },
  ROLES_LIST: { path: '/roles', requiredScope: ['roles:read'] },
  USERS_WRITERS_PUBLICATION_CREATE: {
    path: '/users/writers/:id/publications/new',
    requiredScope: ['users:create'],
  },
  ROLES_EDIT: { path: '/roles/:id', requiredScope: ['roles:read'] },
  PUBLICATION_EDIT: { path: '/publications/:id', requiredScope: ['publications:read'] },
  PUBLICATION_LIST: { path: '/publications', requiredScope: ['publications:read'] },
  WRITER_PUBLICATIONS: {
    path: '/users/writers/:id/publications',
    requiredScope: ['publications:read'],
  },
  POST_LIST: { path: '/posts', requiredScope: ['posts:read'] },
  PUBLICATION_POSTS: { path: '/publications/:id/posts', requiredScope: ['posts:read'] },
  SUBSCRIPTION_VIEW: { path: '/subscriptions/:id', requiredScope: ['subscriptions:read'] },
  SUBSCRIPTIONS_LIST: { path: '/subscriptions', requiredScope: ['subscriptions:read'] },
  LEDGERS: { path: '/ledgers', requiredScope: ['ledgers:read'] },
  LEDGER_ITEMS: { path: '/ledgers/:ledgerId', requiredScope: ['ledger-items:read'] },
  REPORT_VIEW: { path: '/reports/:id', requiredScope: ['reports:read'] },
  TERMS_OF_USE: { path: '/terms-of-use', requiredScope: ['settings:read'] },
  PRIVACY_POLICY: { path: '/privacy-policy', requiredScope: ['settings:read'] },
  PUBLISHER_AGREEMENT: { path: '/publisher-agreement', requiredScope: ['settings:read'] },
  CONTENT_GUIDELINES: { path: '/content-guidelines', requiredScope: ['settings:read'] },
  COPYRIGHT_DISPUTE_POLICY: { path: '/copyright-dispute-policy', requiredScope: ['settings:read'] },
  COPYRIGHT_REPEAT_INFRINGEMENT_POLICY: {
    path: '/copyright-repeat-infringement',
    requiredScope: ['settings:read'],
  },
  CCPA_POLICY: { path: '/ccpa', requiredScope: ['settings:read'] },
  INVOICES: { path: '/invoices', requiredScope: ['invoices:read'] },
  FAQ: { path: '/faq', requiredScope: ['settings:read'] },
  REPORTS: { path: '/reports', requiredScope: ['reports:read'] },
  INVOICE_VIEW: { path: '/invoices/:id', requiredScope: ['invoices:read'] },
  REFUNDS: { path: '/refunds', requiredScope: ['refunds:read'] },
  POST_VIEW: { path: '/posts/:id', requiredScope: ['posts:read'] },
  ACTIVITY_LOGS: { path: '/activity-logs', requiredScope: ['activity-logs:read'] },
  LEDGER_ITEM_VIEW: {
    path: '/ledgers/:ledgerId/ledger-items/:ledgerItemId',
    requiredScope: ['ledger-items:read'],
  },

  MY_PROFILE: { path: '/my-profile', requiredScope: ['profile:read'] },
  SOMETHING_WENT_WRONG: { path: '/something-went-wrong', requiredScope: [] },
  NOT_FOUND: { path: '/page-not-found', requiredScope: [] },
}

export default APP
