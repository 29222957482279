import * as qs from 'querystring'
import apiClient from 'services/axios'
import APIs from './constants'

class SubscriptionService {
  constructor() {
    this.client = apiClient
  }

  getAll = async ({ query }) => {
    return this.client.get(`${APIs.SUBSCRIPTIONS}?${qs.stringify(query)}`)
  }

  getSingle = async ({ path }) => {
    return this.client.get(`${APIs.SINGLE_SUBSCRIPTION.replace(':id', path?.subscriptionId)}`)
  }
}

export default SubscriptionService
