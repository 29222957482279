import { all, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import { doApiCall } from 'redux/helpers'
import UserService from 'services/users'
import APP from 'routes'
import actions from './actions'

const service = new UserService()

export function* FETCH_WRITERS({ payload: { query, path } }) {
  const response = yield doApiCall(service.getAll, { query, path }, actions.SET_STATE)

  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { list: response.data, pagination: response.meta.pagination },
    })
  }
}
export function* CREATE_WRITER({ payload }) {
  const { body, path } = payload
  console.log(body)
  const response = yield doApiCall(service.createUser, { body, path }, actions.SET_STATE)
  if (response.success) {
    yield notification.success({
      message: 'Writer Created',
      description: 'You have added writer successfully!',
    })
    yield history.push(APP.USERS_WRITERS_LIST.path)
  }
}
export function* FETCH_TOP_WRITERS() {
  const response = yield doApiCall(service.getAllTopWriters, {}, actions.SET_STATE)

  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { list: response.data },
    })
  }
}
export function* FETCH_SINGLE_WRITER({ payload: { path, query } }) {
  const response = yield doApiCall(service.getSingle, { query, path }, actions.SET_STATE)
  console.log(response.data)
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { detail: response.data },
    })
  }
}

export function* PERFORM_ACTION_WRITER({ payload: { path, body } }) {
  const response = yield doApiCall(service.performActionUser, { body, path }, actions.SET_STATE)
  if (response.success) {
    yield notification.success({
      message: response.data.message,
    })
    yield history.push(APP.USERS_WRITERS_LIST.path)
  }
}
export function* FETCH_SINGLE_FUND_ACCOUNT({ payload: { path } }) {
  const response = yield doApiCall(service.getSingleFundAccount, { path }, actions.SET_STATE)
  console.log(response.data)
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { fundAccount: response.data },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_TOP_WRITERS, FETCH_TOP_WRITERS),
    takeEvery(actions.FETCH_WRITERS, FETCH_WRITERS),
    takeEvery(actions.CREATE_WRITER, CREATE_WRITER),
    takeEvery(actions.FETCH_SINGLE_FUND_ACCOUNT, FETCH_SINGLE_FUND_ACCOUNT),
    takeEvery(actions.FETCH_SINGLE_WRITER, FETCH_SINGLE_WRITER),
    takeEvery(actions.PERFORM_ACTION_WRITER, PERFORM_ACTION_WRITER),
  ])
}
