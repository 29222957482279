import * as qs from 'querystring'
import apiClient from 'services/axios'
import APIs from './constants'

class UsersService {
  constructor() {
    this.client = apiClient
  }

  getAll = async ({ query }) => {
    return this.client.get(`${APIs.GET_ALL}?${qs.stringify(query)}`)
  }

  getSingle = async ({ path, query }) => {
    return this.client.get(
      `${APIs.GET_SINGLE.replace(':alias/:userId', `${path.alias}/${path?.userId}`)}?${qs.stringify(
        query,
      )}`,
    )
  }
}

export default UsersService
