import { combineReducers } from 'redux'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import writers from './users/writers/reducers'
import readers from './users/readers/reducers'
import topic from './topic/reducers'
import admins from './users/admins/reducers'
import roles from './roles/reducers'
import publications from './publication/reducers'
import posts from './post/reducers'
import adminSettings from './adminSettings/reducers'
import payments from './payments/reducers'
import reports from './report/reducers'
import subscriptions from './subscription/reducers'
import ledgers from './ledger/reducers'
import refunds from './refunds/reducers'
import analytics from './analytics/reducers'
import comments from './comment/reducers'
import activityLogs from './activityLogs/reducers'

export default () =>
  combineReducers({
    user,
    menu,
    writers,
    readers,
    settings,
    topic,
    admins,
    roles,
    publications,
    posts,
    adminSettings,
    payments,
    reports,
    subscriptions,
    ledgers,
    refunds,
    analytics,
    comments,
    activityLogs,
  })
