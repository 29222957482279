import { all, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import { doApiCall } from 'redux/helpers'
import RoleService from 'services/roles'
import APP from 'routes'

import actions from './actions'

const service = new RoleService()

export function* FETCH_ROLES({ payload: { query } }) {
  const response = yield doApiCall(service.getAll, { query }, actions.SET_STATE)
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { list: response.data, pagination: response.meta.pagination },
    })
  }
}
export function* CREATE_ROLES({ payload }) {
  const { body } = payload
  // console.log('===><==', payload)
  const response = yield doApiCall(service.createRole, { body }, actions.SET_STATE)
  console.log('TEST', response)
  if (response.success) {
    yield notification.success({
      message: 'Role Created',
      description: 'You have added Role successfully!',
    })
    yield history.push(APP.ROLES_LIST)
  }
}
export function* FETCH_SINGLE_ROLE({ payload: { path } }) {
  const response = yield doApiCall(service.getSingle, { path }, actions.SET_STATE)
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { detail: response.data },
    })
  }
}
export function* UPDATE_ROLE({ payload: { path, body } }) {
  const response = yield doApiCall(service.updateRole, { path, body }, actions.SET_STATE)
  if (response.success) {
    yield notification.success({
      message: response.data.message,
      // description: response.data.message,
    })
    yield history.push(APP.ROLES_LIST)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_ROLES, FETCH_ROLES)])
  yield all([takeEvery(actions.CREATE_ROLES, CREATE_ROLES)])
  yield all([takeEvery(actions.UPDATE_ROLE, UPDATE_ROLE)])
  yield all([takeEvery(actions.FETCH_SINGLE_ROLE, FETCH_SINGLE_ROLE)])
}
