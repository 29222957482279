import moment from 'moment'
import { isArray } from 'react-stockcharts/lib/utils'
import { history, store } from 'index'
import * as qs from 'querystring'
import { useLocation } from 'react-router-dom'

export const errors = (err) => {
  const messages = {}

  // eslint-disable-next-line consistent-return
  Object.keys(err).forEach((key) => {
    const errorMessage = err[key]
    if (!errorMessage) return ''
    messages[key] = typeof errorMessage === 'string' ? errorMessage : errorMessage.join(', ')
  })

  return messages
}

export const getScopeFromRoles = (roles) => {
  const scope = new Set([])
  if (!roles || !roles.length) {
    return Array.from(scope)
  }
  for (let i = 0; i < roles.length; i += 1) {
    roles[i].scope.forEach((permission) => scope.add(permission))
  }

  return Array.from(scope)
}

export const GetQueryParams = () => {
  const s = useLocation().search
  const params = qs.parse(s.substr(1))
  return params ?? {}
}

export const handleQueryChange = (param, value) => {
  const urlObj = history.location
  const params = qs.parse(urlObj.search.substr(1)) ?? {}
  params[param] = value
  return `${urlObj.pathname}?${qs.stringify(params)}`
}

export const formErrorFeedback = (err, key) => {
  if (err[key]) {
    return {
      hasFeedback: true,
      validateStatus: err[key] ? 'error' : '',
      help: err[key],
    }
  }
  return {}
}

export const formatAmountAsCurrency = (value) => {
  return value
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'AED',
        maximumSignificantDigits: 20,
      }).format(value)
    : 0
}

export function formatDate(date, format, sign) {
  if (typeof date === 'string') date = new Date(date)
  function cb(key) {
    const f = new Intl.DateTimeFormat('en', { [key]: format[key] })
    return f.format(date)
  }

  return Object.keys(format).map(cb).join(sign)
}

export function dateViewFormat(date, format) {
  if (moment(date).isValid()) {
    return moment(date).format(format)
  }
  return '--'
}
export function dateGetYear(date) {
  if (moment(date).isValid()) {
    return moment(date).format('YYYY')
  }
  return '--'
}

export const checkDate = (dateTime, formate) => {
  const date = moment(dateTime, formate, true)

  if (date.isValid()) {
    return true
  }
  return false
}

export const getDateDiff = (firstDate, secondDate, unit) => {
  return firstDate.diff(secondDate, unit)
}

export const addingInDate = (currentDate, amount, unit) => {
  return currentDate.add(amount, unit)
}

const getParseDate = (list) => {
  const parsedDate = {}
  if (list && Array.isArray(list) && list.length) {
    for (let i = 0; i < list.length; i += 1) {
      const ds = list[i]
      if (parsedDate[`${ds.index}`]) {
        parsedDate[`${ds.index}`] = ds.value
      } else {
        parsedDate[`${ds.index}`] = ds.value
      }
    }
  }
  return parsedDate
}

const DATE_FORMATE = 'YYYY-MM-DD'

export const getChartData = (list, date) => {
  const firstDate = moment([date[0]], DATE_FORMATE)
  const secondDate = moment(date[1], DATE_FORMATE)
  const dateDiff = getDateDiff(secondDate, firstDate, 'day')

  const parsedDates = getParseDate(list)

  const chartData = { labels: [], data: [] }

  let total = 0

  let rollingDate = firstDate
  for (let i = 0; i < dateDiff; i += 1) {
    if (parsedDates[`${rollingDate.format(DATE_FORMATE)}`]) {
      const data = parsedDates[`${rollingDate.format(DATE_FORMATE)}`]
      total += +data
      chartData.labels.push(`${rollingDate.format('dddd, MMMM Do YY')}`)
      chartData.data.push(data)
    }
    rollingDate = addingInDate(rollingDate, 1, 'day')
  }

  return { chartData, total }
}

export const verifyScope = (required) => {
  const {
    user: {
      roleId: { scope },
    },
  } = store.getState()
  const userScopeList = scope?.split(',')
  if (isArray(required)) {
    return required.reduce((a, b) => userScopeList.includes(a) && userScopeList.includes(b))
  }
  return userScopeList.includes(required)
}

export const setSortValue = (sortVal) => {
  switch (sortVal) {
    case 'createdAt:desc': {
      return 'Newest'
    }
    case 'createdAt:asc': {
      return 'Oldest'
    }
    case 'price:desc': {
      return 'Price (High - Low)'
    }
    case 'price:asc': {
      return 'Price (Low - High)'
    }
    case 'name:desc': {
      return 'Name (Z - A)'
    }
    case 'name:asc': {
      return 'Name (A - Z)'
    }
    default: {
      return 'Newest'
    }
  }
}

export const setFilterValue = (filterVal) => {
  switch (filterVal) {
    case -1: {
      return 'Archived'
    }
    case 0: {
      return 'Pending'
    }
    case 1: {
      return 'Approved'
    }
    case 2: {
      return 'Blocked'
    }
    default: {
      return 'All'
    }
  }
}

export const setCommonFilterValue = (filterVal) => {
  switch (filterVal) {
    case -1: {
      return 'Archived'
    }

    case 1: {
      return 'Active'
    }
    default: {
      return 'All'
    }
  }
}

export const GetPinToTopValue = (value) => {
  let val = 'false'
  if (value.showOnTop) {
    if (value.showOnTop === true) {
      val = 'true'
    }
  }
  return val
}
export const getSlugFromFilesArray = (arr) => {
  if (!arr || arr.length === 0) return null
  const slug = arr[0]
  console.log(slug)
  return slug
}

export const optionTopicChange = (arr) => {
  const options = arr.map((item) => {
    return {
      createAt: item.createAt,
      updatedAt: item.updatedAt,
      id: item.id,
      value: item.title,
    }
  })
  return options
}
export function capitalizeFirstLetter(string = '') {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function secondsToHms(d) {
  d = Number(d)
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)
  const s = Math.floor((d % 3600) % 60)

  const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : ''
  const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : ''
  const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : ''
  return hDisplay + mDisplay + sDisplay
}
export function changePaiseToRupees(d = 0) {
  const str = `₹${d / 100}`
  return str
}
