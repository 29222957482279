// import { all, takeEvery, put } from 'redux-saga/effects'
import { all, takeEvery, put } from 'redux-saga/effects'

import { notification } from 'antd'
// import { history } from 'index'
import { doApiCall } from 'redux/helpers'
import PostService from 'services/post'

// import APP from 'routes'
import actions from './actions'

const service = new PostService()

export function* FETCH_POSTS({ payload: { query } }) {
  const response = yield doApiCall(service.getAll, { query }, actions.SET_STATE)

  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { list: response.data, pagination: response.meta.pagination },
    })
  }
}
export function* FETCH_SINGLE({ payload: { path } }) {
  const response = yield doApiCall(service.getSingle, { path }, actions.SET_STATE)

  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { detail: response.data },
    })
  }
}

export function* PERFORM_ACTION_POST({ payload: { path, body } }) {
  console.log('<=========>', path)
  const response = yield doApiCall(service.performActionPost, { path, body }, actions.SET_STATE)
  // const action = body.action === 'block' ? 'Blocked' : 'Published'

  if (response.success) {
    yield notification.success({
      message: response.data.message,
      // description: response.data.message,
    })
    yield put({
      type: actions.FETCH_SINGLE,
      payload: { path },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_POSTS, FETCH_POSTS)])
  yield all([takeEvery(actions.FETCH_SINGLE, FETCH_SINGLE)])
  yield all([takeEvery(actions.PERFORM_ACTION_POST, PERFORM_ACTION_POST)])
}
