import APP from 'routes'

export default async function getMenuData({ userScope }) {
  const allItems = [
    // VB:REPLACE-START:MENU-CONFIG
    {
      category: true,
      title: 'Dashboards',
      key: 'q7r3hr',
    },
    {
      title: 'Dashboards',
      key: '__dashboard',
      ...APP.DASHBOARD,
      icon: 'fe fe-home',
    },
    {
      category: true,
      title: 'Resources',
      key: 'dsdsdsad',
    },
    {
      title: 'Users',
      key: '6rq4ze6',
      icon: 'fe fe-users',
      children: [
        {
          title: 'Writers ',
          key: 'es38wke',
          ...APP.USERS_WRITERS_LIST,
          icon: 'fe fe-feather',
        },
        {
          title: 'Readers ',
          key: 'e39s9wke',
          ...APP.USERS_READERS_LIST,
          icon: 'fe fe-eye',
        },
        {
          title: 'Admins ',
          key: 'e40s9wke',
          ...APP.USERS_ADMIN_LIST,
          icon: 'fa fa-user-secret',
        },
      ],
    },
    {
      title: 'Publications ',
      key: '6rq5z2e6',
      ...APP.PUBLICATION_LIST,
      icon: 'fe fe-book-open',
    },
    {
      title: 'Posts',
      key: '6rq5z1e6',
      ...APP.POST_LIST,
      icon: 'fe fe-file-text',
    },
    {
      title: 'Subscriptions ',
      key: '6rq1z1e6',
      ...APP.SUBSCRIPTIONS_LIST,
      icon: 'fa fa-sticky-note',
    },
    {
      title: 'Invoices ',
      key: '6rq1zdsa1e6',
      ...APP.INVOICES,
      icon: 'fe fe-credit-card',
    },
    {
      title: 'Refunds ',
      key: 'sdsadasdaq',
      ...APP.REFUNDS,
      icon: 'fa fa-ticket',
    },
    {
      title: 'Ledgers ',
      key: '6rasse6',
      ...APP.LEDGERS,
      icon: 'fe fe-check-square',
    },
    {
      title: 'Reports ',
      key: '6rase6',
      ...APP.REPORTS,
      icon: 'fe fe-alert-circle',
    },
    {
      title: 'Activity Logs',
      key: '6rassesda6',
      ...APP.ACTIVITY_LOGS,
      icon: 'fe fe-refresh-cw',
    },
    {
      category: true,
      title: 'Settings ',
      key: 'q7r13hr',
    },

    {
      title: 'Role Management ',
      key: '6rq5ze6',
      ...APP.ROLES_LIST,
      icon: 'fe fe-hard-drive',
    },
    {
      title: 'FAQs ',
      key: 'es38ua3wese',
      ...APP.FAQ,
      icon: 'fa fa-question',
    },
    {
      category: true,
      title: 'Terms & Conditions',
      key: '6rAS4ze6',
      // icon: 'fe fe-book',
      // children: [],
    },
    {
      title: 'Terms of Use ',
      key: 'es38w3se',
      ...APP.TERMS_OF_USE,
      icon: 'fa fa-wpforms',
    },
    {
      title: 'Privacy Policy ',
      key: 'es38asd3se',
      ...APP.PRIVACY_POLICY,
      icon: 'fe fe-shield',
    },
    {
      title: 'Publisher Agreement ',
      key: 'es38w321e',
      ...APP.PUBLISHER_AGREEMENT,
      icon: 'fe fe-briefcase',
    },
    {
      title: 'Content Guidelines ',
      key: 'es38asds3se',
      ...APP.CONTENT_GUIDELINES,
      icon: 'fa fa-list-alt',
    },
    {
      title: 'Copyright Dispute Policy ',
      key: 'es38ui3se',
      ...APP.COPYRIGHT_DISPUTE_POLICY,
      icon: 'fa fa-book',
    },
    {
      title: 'Repeat Infringement Pol. ',
      key: 'es3aqui3se',
      ...APP.COPYRIGHT_REPEAT_INFRINGEMENT_POLICY,
      icon: 'fe fe-alert-triangle',
    },
    {
      title: 'CCPA Policy ',
      key: 'es38ua3se',
      ...APP.CCPA_POLICY,
      icon: 'fa fa-list',
    },
  ]
  return allItems.filter((menuItem) => {
    if (!menuItem.requiredScope || !menuItem.requiredScope.length) {
      return true
    }
    for (let i = 0; i < menuItem.requiredScope.length; i += 1) {
      if (!userScope.includes(menuItem.requiredScope[i])) {
        return false
      }
    }
    return true
  })
}
