import actions from './actions'

const initialState = {
  list: [],
  pagination: {},
  loading: false,
  detail: {},
  formErrors: {},
  addresses: [],
}

export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
