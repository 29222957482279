import * as qs from 'querystring'
import apiClient from 'services/axios'
import APIs from './constants'

class RoleService {
  constructor() {
    this.client = apiClient
  }

  getAll = async ({ query }) => {
    return this.client.get(`${APIs.GET_ALL}?${qs.stringify(query)}`)
  }

  createRole = async ({ body }) => {
    return this.client.post(`${APIs.GET_ALL}`, body)
  }

  getSingle = async ({ path }) => {
    return this.client.get(`${APIs.GET_SINGLE.replace(':id', `${path?.roleId}`)}`)
  }

  updateRole = async ({ body, path }) => {
    return this.client.patch(`${APIs.GET_SINGLE.replace(':id', `${path?.roleId}`)}`, body)
  }
}

export default RoleService
