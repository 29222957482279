import { call, put } from 'redux-saga/effects'
import { notification } from 'antd'
// import ROUTES from 'routes'
import { isString } from 'lodash'
import { errors } from '../helper'

export function* doApiCall(service, payload, ACTION_SET_STATE) {
  yield showLoading(ACTION_SET_STATE, true)
  yield put({ type: ACTION_SET_STATE, payload: { formErrors: {} } })
  try {
    const response = yield call(service, payload)
    // console.log('Res=============>', response)
    yield showLoading(ACTION_SET_STATE, false)
    if (response?.status === 204) {
      return { success: true }
    }
    return response?.data
  } catch (e) {
    console.log('🚀 ~ file: helpers.js ~ line 19 ~ function*doApiCall ~ e', e)
    yield showLoading(ACTION_SET_STATE, false)
    // console.log('e===================>', e)
    const { response } = e
    console.log(e.errors)
    if (response) {
      const { status, data } = response
      console.log('======> Code', data.message)
      if (status === 422) {
        yield put({
          type: ACTION_SET_STATE,
          payload: { formErrors: errors(data.errors) },
        })
      }
      if (data.message && data.message.length > 0) {
        //   console.log('======> Code', 12332)

        notification.error({
          message: data.message,
        })
      } else if (isString(data)) {
        notification.error({
          message: data,
        })
      }
    } else {
      notification.error({
        message: 'Network Error Detected.',
        description: 'Please check your internet connection.',
      })
    }
  }
  return { success: false }
}

export function* showLoading(action, loading) {
  yield put({ type: action, payload: { loading } })
}
