import * as qs from 'querystring'
import apiClient from 'services/axios'
import APIs from './constants'

class UsersService {
  constructor() {
    this.client = apiClient
  }

  getAll = async ({ query, path }) => {
    return this.client.get(`${APIs.GET_ALL.replace(':alias', path?.alias)}?${qs.stringify(query)}`)
  }

  getAllTopWriters = async () => {
    return this.client.get(`${APIs.TOP_WRITERS}`)
  }

  getSingle = async ({ path, query }) => {
    return this.client.get(
      `${APIs.GET_SINGLE.replace(':alias/:id', `${path.alias}/${path?.userId}`)}?${qs.stringify(
        query,
      )}`,
    )
  }

  getSingleFundAccount = async ({ path }) => {
    return this.client.get(`${APIs.GET_SINGLE_FUND_ACCOUNT.replace(':id', `${path.userId}`)}`)
  }

  createUser = async ({ body, path }) => {
    return this.client.post(`${APIs.CREATE_USER.replace(':alias', path?.alias)}`, body)
  }

  performActionUser = async ({ body, path }) => {
    return this.client.post(`${APIs.PERFORM_ACTION_USER.replace(':id', path?.userId)}`, body)
  }

  updateAdmin = async ({ body, path }) => {
    return this.client.patch(
      `${APIs.GET_SINGLE.replace(':alias/:id', `${path.alias}/${path?.userId}`)}`,
      body,
    )
  }
}

export default UsersService
