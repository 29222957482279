// import { all, takeEvery, put } from 'redux-saga/effects'
import { all, takeEvery, put } from 'redux-saga/effects'

// import { notification } from 'antd'
// import { history } from 'index'
import { doApiCall } from 'redux/helpers'
import PaymentService from 'services/payment'
// import APP from 'routes'
import actions from './actions'

const service = new PaymentService()

export function* FETCH_PAYMENTS({ payload: { query } }) {
  const response = yield doApiCall(service.getAll, { query }, actions.SET_STATE)

  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { list: response.data, pagination: response.meta.pagination },
    })
  }
}
export function* FETCH_SINGLE({ payload: { path } }) {
  const response = yield doApiCall(service.getSingle, { path }, actions.SET_STATE)

  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { detail: response.data },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_PAYMENTS, FETCH_PAYMENTS)])
  yield all([takeEvery(actions.FETCH_SINGLE, FETCH_SINGLE)])
}
