import { all, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import { doApiCall } from 'redux/helpers'
import UserService from 'services/users'
import APP from 'routes'
import actions from './actions'

const service = new UserService()

export function* FETCH_ADMINS({ payload: { query, path } }) {
  const response = yield doApiCall(service.getAll, { query, path }, actions.SET_STATE)

  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { list: response.data, pagination: response.meta.pagination },
    })
  }
}
export function* CREATE_ADMIN({ payload }) {
  const { body, path } = payload
  const response = yield doApiCall(service.createUser, { body, path }, actions.SET_STATE)
  if (response.success) {
    yield notification.success({
      message: 'Admin Created',
      description: 'You have added admin successfully!',
    })

    yield history.push(APP.USERS_ADMIN_LIST.path)
  }
}
export function* FETCH_SINGLE_ADMIN({ payload: { path, query } }) {
  const response = yield doApiCall(service.getSingle, { query, path }, actions.SET_STATE)
  console.log(response.data)
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { detail: response.data },
    })
  }
}

export function* PERFORM_ACTION_ADMIN({ payload: { path, body } }) {
  const response = yield doApiCall(service.performActionUser, { body, path }, actions.SET_STATE)
  if (response.success) {
    yield notification.success({
      message: response.data.message,
    })
    yield history.push(APP.USERS_WRITERS_LIST.path)
  }
}
export function* UPDATE_ADMIN({ payload: { path, body } }) {
  const response = yield doApiCall(service.updateAdmin, { body, path }, actions.SET_STATE)
  console.log(response.data)
  if (response.success) {
    yield notification.success({
      message: response.data,
    })
    yield history.push(APP.USERS_ADMIN_LIST.path)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ADMINS, FETCH_ADMINS),
    takeEvery(actions.CREATE_ADMIN, CREATE_ADMIN),
    takeEvery(actions.FETCH_SINGLE_ADMIN, FETCH_SINGLE_ADMIN),
    takeEvery(actions.UPDATE_ADMIN, UPDATE_ADMIN),
    takeEvery(actions.PERFORM_ACTION_ADMIN, PERFORM_ACTION_ADMIN),
  ])
}
