import { all, put, takeEvery } from 'redux-saga/effects'
import { doApiCall } from 'redux/helpers'
import TopicService from 'services/topics'
import actions from './actions'

const service = new TopicService()

export function* FETCH_TOPICS({ payload: { query } }) {
  const response = yield doApiCall(service.getAll, { query }, actions.SET_STATE)
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { list: response.data, pagination: response.meta.pagination },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_TOPICS, FETCH_TOPICS)])
}
