import { all, takeEvery, put } from 'redux-saga/effects'
// import { history } from 'index'
import { doApiCall } from 'redux/helpers'
import ActivityLogsService from 'services/activityLogs'

// import APP from 'routes'
import actions from './actions'

const service = new ActivityLogsService()

export function* FETCH_ACTIVITY_LOGS({ payload }) {
  const { query } = payload
  const response = yield doApiCall(service.getAll, { query }, actions.SET_STATE)
  console.log('TEST', response)
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { list: response.data, pagination: response.meta.pagination },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_ACTIVITY_LOGS, FETCH_ACTIVITY_LOGS)])
}
