import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import StylesLoader from './stylesLoader'
import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Localization from './localization'
import RouterComponent from './router'
import * as serviceWorker from './serviceWorker'

// middlewared
const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]
// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }

Sentry.init({
  dsn: process.env.REACT_APP_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

const store = createStore(reducers(), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

ReactDOM.render(
  <Provider store={store}>
    <StylesLoader>
      <Localization>
        <RouterComponent history={history} />
      </Localization>
    </StylesLoader>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { store, history }
