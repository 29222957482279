export const reportReasons = {
  1: "It's spam.",
  2: 'Hate speech or symbols',
  3: 'False information',
  4: 'Bullying or harassment',
  5: 'Violence or dangerous organisations',
  6: 'Intellectual property violation',
  7: 'Rules violation',
}
export const socialLogins = {
  0: 'Hubscribe',

  1: 'Facebook',

  2: 'Google',

  3: 'Linkedin',
}
export const analyticsData = {
  views: 'postViews',
  bookmarks: 'postBookmarks',
  likes: 'postLikes',
  comments: 'postComments',
}

export const EDITOR_CONFIG = {
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'underline',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      '|',
      'undo',
      'redo',
    ],
  },
  language: 'en',
}
