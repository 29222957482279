import * as qs from 'querystring'
import apiClient from 'services/axios'
import APIs from './constants'

class PostService {
  constructor() {
    this.client = apiClient
  }

  //   createPublication = async ({ body }) => {
  //     return this.client.post(`${APIs.PUBLICATION}`, body)
  //   }

  getAll = async ({ query }) => {
    return this.client.get(`${APIs.POSTS}?${qs.stringify(query)}`)
  }

  getSingle = async ({ path }) => {
    return this.client.get(`${APIs.SINGLE_POST.replace(':id', path.id)}`)
  }

  performActionPost = async ({ body, path }) => {
    console.log('{+++++===]', path)
    return this.client.post(`${APIs.PERFORM_ACTION_POST.replace(':id', path.id)}`, body)
  }
}

export default PostService
