import { all, takeEvery, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import { doApiCall } from 'redux/helpers'
import PublicationService from 'services/publication'
import APP from 'routes'
import actions from './actions'

const service = new PublicationService()

export function* CREATE_PUBLICATION({ payload }) {
  const { body } = payload
  console.log('===><==', payload)
  const response = yield doApiCall(service.createPublication, { body }, actions.SET_STATE)
  console.log('TEST', response)
  if (response.success) {
    yield notification.success({
      message: 'Publication Created',
      description: 'You have added Publication successfully!',
    })
    yield history.push(APP.USERS_WRITERS_LIST)
  }
}

export function* FETCH_PUBLICATIONS({ payload: { query } }) {
  const response = yield doApiCall(service.getAll, { query }, actions.SET_STATE)

  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { list: response.data, pagination: response.meta.pagination },
    })
  }
}
export function* FETCH_TRENDING_PUBLICATIONS() {
  console.log(111)
  const response = yield doApiCall(service.getTrendingPublications, {}, actions.SET_STATE)

  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { list: response.data },
    })
  }
}
export function* FETCH_SINGLE({ payload: { path } }) {
  const response = yield doApiCall(service.getSingle, { path }, actions.SET_STATE)

  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { detail: response.data },
    })
  }
}
export function* UPDATE_PUBLICATION({ payload }) {
  const { body, path } = payload
  console.log(path)
  const response = yield doApiCall(service.updatePublication, { body, path }, actions.SET_STATE)
  console.log('TEST', response)
  if (response.success) {
    yield notification.success({
      message: response.data.message,
      // description: response.data.message,
    })
    yield put({
      type: actions.FETCH_SINGLE,
      payload: { path: response.data.slug },
    }) // yield put({
    //   type: actions.SET_STATE,
    //   payload: {
    //     details: response.data,
    //     loading: false,
    //     authorized: true,
    //   },
    // })
    yield history.push(APP.USERS_WRITERS_LIST)
  }
}
export function* PERFORM_ACTION_PUBLICATION({ payload: { path, body } }) {
  console.log('<=========>', path)
  const response = yield doApiCall(
    service.performActionPublication,
    { path, body },
    actions.SET_STATE,
  )
  const action = body.action === 'block' ? 'Blocked' : 'Published'

  if (response.success) {
    yield notification.success({
      message: `Publication successfully ${action}`,
      // description: response.data.message,
    })
    yield put({
      type: actions.FETCH_SINGLE,
      payload: { path: { id: path.id } },
    })
  }
}
export default function* rootSaga() {
  yield all([takeEvery(actions.CREATE_PUBLICATION, CREATE_PUBLICATION)])
  yield all([takeEvery(actions.FETCH_TRENDING_PUBLICATIONS, FETCH_TRENDING_PUBLICATIONS)])
  yield all([takeEvery(actions.FETCH_PUBLICATIONS, FETCH_PUBLICATIONS)])

  yield all([takeEvery(actions.UPDATE_PUBLICATION, UPDATE_PUBLICATION)])
  yield all([takeEvery(actions.PERFORM_ACTION_PUBLICATION, PERFORM_ACTION_PUBLICATION)])
  yield all([takeEvery(actions.FETCH_SINGLE, FETCH_SINGLE)])

  //   yield all([takeEvery(actions.PERFORM_ACTION_READER, PERFORM_ACTION_READER)])
}
