import { all, takeEvery, put } from 'redux-saga/effects'
import { doApiCall } from 'redux/helpers'
import SubscriptionService from 'services/subscriptions'
import actions from './actions'

const service = new SubscriptionService()

export function* FETCH_SUBSCRIPTIONS({ payload: { query } }) {
  const response = yield doApiCall(service.getAll, { query }, actions.SET_STATE)

  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { list: response.data, pagination: response.meta.pagination },
    })
  }
}
export function* FETCH_SINGLE({ payload: { path } }) {
  const response = yield doApiCall(service.getSingle, { path }, actions.SET_STATE)

  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { detail: response.data },
    })
  }
}

export default function* rootSaga() {
  //   yield all([takeEvery(actions.CREATE_PUBLICATION, CREATE_PUBLICATION)])
  yield all([takeEvery(actions.FETCH_SUBSCRIPTIONS, FETCH_SUBSCRIPTIONS)])
  yield all([takeEvery(actions.FETCH_SINGLE, FETCH_SINGLE)])
}
