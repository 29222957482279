const APIs = {
  GET_ALL: 'admin/users/:alias',
  GET_SINGLE: 'admin/users/:alias/:id',
  CREATE_USER: 'admin/users/:alias',
  PERFORM_ACTION_USER: 'admin/users/:id/perform-action',
  TOP_WRITERS: 'users/trending-writers',
  GET_SINGLE_FUND_ACCOUNT: 'admin/users/:id/fund-account',
}

export default APIs
