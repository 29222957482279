import apiClient from 'services/axios'
import APIs from './constants'

class AdminSettingsService {
  constructor() {
    this.client = apiClient
  }

  getAdminSettings = async ({ path }) => {
    return this.client.get(`${APIs.ADMIN_SETTINGS_SCOPES.replace(':key', path.key)}`)
  }

  postAdminSettings = async ({ body }) => {
    return this.client.post(`${APIs.ADMIN_SETTINGS}`, body)
  }
}

export default AdminSettingsService
