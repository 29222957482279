import * as qs from 'querystring'
import apiClient from 'services/axios'
import APIs from './constants'

class ReportService {
  constructor() {
    this.client = apiClient
  }

  //   createPublication = async ({ body }) => {
  //     return this.client.post(`${APIs.PUBLICATION}`, body)
  //   }

  getAll = async ({ query }) => {
    return this.client.get(`${APIs.REPORTS}?${qs.stringify(query)}`)
  }

  getSingle = async ({ path }) => {
    return this.client.get(`${APIs.SINGLE_REPORTS.replace(':id', path?.reportId)}`)
  }

  //   updatePublication = async ({ body, path }) => {
  //     return this.client.patch(`${APIs.SINGLE_PUBLICATION.replace(':slug', path.slug)}`, body)
  //   }
}

export default ReportService
