import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import store from 'store'
import { history } from 'index'
import { doApiCall } from 'redux/helpers'
import AuthService from 'services/auth'
import { get } from 'lodash'
import APP from 'routes'
import { getScopeFromRoles } from 'helper'
import actions from './actions'
import menuActions from '../menu/actions'

const authService = new AuthService()

export function* LOGIN({ payload: { body } }) {
  const response = yield doApiCall(authService.login, { body }, actions.SET_STATE)
  if (response.success) {
    const accessToken = get(response.data, 'access_token')
    yield notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in!',
    })
    store.set('accessToken', accessToken)
    yield put({
      type: actions.LOAD_CURRENT_ACCOUNT,
    })
    const scope = getScopeFromRoles(response?.detail?.roles)
    yield put({
      type: actions.SET_STATE,
      payload: {
        scope,
        authorized: true,
      },
    })
    yield history.push(APP.DASHBOARD)
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  if (!store.get('accessToken') || !store.get('accessToken').length) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        detail: {},
        authorized: false,
        loading: false,
      },
    })
    return
  }
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  })
  try {
    const response = yield call(authService.currentAccount, {})
    if (response?.status < 400) {
      const detail = response.data?.data

      const scope = getScopeFromRoles(detail?.roles)
      yield put({
        type: menuActions.GET_DATA,
        payload: { userScope: scope },
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          detail,
          scope,
          authorized: true,
        },
      })
    }
  } catch (e) {
    const { response } = e
    if (response.status === 401) {
      yield put({
        type: actions.LOGOUT,
        payload: {},
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })
}

export function* UPDATE_CURRENT_ACCOUNT({ payload: { body } }) {
  const response = yield doApiCall(authService.updateProfile, { body }, actions.SET_STATE)
  if (response.success) {
    yield notification.success({
      message: 'Profile Updated',
      description: 'Your profile has been updated successfully.',
    })
    yield put({
      type: actions.LOAD_CURRENT_ACCOUNT,
      payload: {},
    })
  }
}
export function* LOGOUT() {
  store.remove('accessToken')
  localStorage.clear()
  yield put({
    type: actions.SET_STATE,
    payload: {
      detail: {},
      authorized: false,
      loading: false,
    },
  })
  yield history.push(APP.ADMIN_LOGIN.path)
}

export function* UPDATE_MY_PROFILE({ payload: { body } }) {
  const response = yield doApiCall(authService.updateProfile, { body }, actions.SET_STATE)
  if (response.success) {
    yield notification.success({
      message: response.data,
      // description: 'Your profile has been updated successfully.',
    })
    yield put({
      type: actions.LOAD_CURRENT_ACCOUNT,
      payload: {},
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.UPDATE_MY_PROFILE, UPDATE_MY_PROFILE),
    takeEvery(actions.LOGOUT, LOGOUT),

    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
