import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import writers from './users/writers/sagas'
import readers from './users/readers/sagas'
import topic from './topic/sagas'
import admins from './users/admins/sagas'
import roles from './roles/sagas'
import publications from './publication/sagas'
import posts from './post/sagas'
import adminSettings from './adminSettings/sagas'
import payments from './payments/sagas'
import report from './report/sagas'
import subscription from './subscription/sagas'
import ledger from './ledger/sagas'
import refunds from './refunds/sagas'
import analytics from './analytics/sagas'
import comments from './comment/sagas'
import activityLogs from './activityLogs/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    writers(),
    readers(),
    topic(),
    admins(),
    roles(),
    publications(),
    posts(),
    adminSettings(),
    payments(),
    report(),
    subscription(),
    ledger(),
    refunds(),
    analytics(),
    comments(),
    activityLogs(),
  ])
}
