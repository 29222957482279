import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch, Router } from 'react-router-dom'
// import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'
import APP from 'routes'
import Authorizer from 'authorizer'

const routes = [
  // Admin Login

  {
    ...APP.ADMIN_LOGIN,
    Component: lazy(() => import('pages/auth/adminLogin')),
    exact: true,
  },
  {
    ...APP.DASHBOARD,
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
  },
  {
    ...APP.USERS_WRITERS_LIST,
    Component: lazy(() => import('pages/users/writers/writerList')),
    exact: true,
  },
  {
    ...APP.USERS_READERS_LIST,
    Component: lazy(() => import('pages/users/readers/readersList')),
    exact: true,
  },
  {
    ...APP.USERS_WRITERS_EDIT,
    Component: lazy(() => import('pages/users/writers/writerForm')),
    exact: true,
  },
  {
    ...APP.USERS_READERS_EDIT,
    Component: lazy(() => import('pages/users/readers/readerForm')),
    exact: true,
  },
  {
    ...APP.USERS_ADMIN_LIST,
    Component: lazy(() => import('pages/users/admins/adminList')),
    exact: true,
  },
  {
    ...APP.USERS_ADMIN_EDIT,
    Component: lazy(() => import('pages/users/admins/adminForm')),
    exact: true,
  },
  {
    ...APP.ROLES_LIST,
    Component: lazy(() => import('pages/roles/roleList')),
    exact: true,
  },
  {
    ...APP.ROLES_EDIT,
    Component: lazy(() => import('pages/roles/roleForm')),
    exact: true,
  },
  {
    ...APP.WRITER_PUBLICATIONS,
    Component: lazy(() => import('pages/users/writers/writerPublications')),
    exact: true,
  },
  {
    ...APP.PUBLICATION_EDIT,
    Component: lazy(() => import('pages/publications/publicationEdit')),
    exact: true,
  },
  {
    ...APP.PUBLICATION_LIST,
    Component: lazy(() => import('pages/publications/publicationList')),
    exact: true,
  },
  {
    ...APP.POST_LIST,
    Component: lazy(() => import('pages/posts/postList')),
    exact: true,
  },
  {
    ...APP.PUBLICATION_POSTS,
    Component: lazy(() => import('pages/publications/publicationPosts')),
    exact: true,
  },
  {
    ...APP.SUBSCRIPTION_VIEW,
    Component: lazy(() => import('pages/subscriptions/subscriptionView')),
    exact: true,
  },
  {
    ...APP.SUBSCRIPTIONS_LIST,
    Component: lazy(() => import('pages/subscriptions/subscriptionList')),
    exact: true,
  },
  {
    ...APP.TERMS_OF_USE,
    Component: lazy(() => import('pages/settings/termsAndConditions/termsOfUse')),
    exact: true,
  },
  {
    ...APP.PRIVACY_POLICY,
    Component: lazy(() => import('pages/settings/termsAndConditions/privacyPolicy')),
    exact: true,
  },
  {
    ...APP.PUBLISHER_AGREEMENT,
    Component: lazy(() => import('pages/settings/termsAndConditions/publisherAgreement')),
    exact: true,
  },
  {
    ...APP.CONTENT_GUIDELINES,
    Component: lazy(() => import('pages/settings/termsAndConditions/contentGuidelines')),
    exact: true,
  },
  {
    ...APP.COPYRIGHT_DISPUTE_POLICY,
    Component: lazy(() => import('pages/settings/termsAndConditions/copyrightDisputePolicy')),
    exact: true,
  },
  {
    ...APP.COPYRIGHT_REPEAT_INFRINGEMENT_POLICY,
    Component: lazy(() => import('pages/settings/termsAndConditions/copyrighRepeatInfringement')),
    exact: true,
  },
  {
    ...APP.CCPA_POLICY,
    Component: lazy(() => import('pages/settings/termsAndConditions/ccpaPolicy')),
    exact: true,
  },
  {
    ...APP.INVOICES,
    Component: lazy(() => import('pages/invoices/invoicesList')),
    exact: true,
  },
  {
    ...APP.FAQ,
    Component: lazy(() => import('pages/settings/frequentlyAskedQustions')),
    exact: true,
  },
  {
    ...APP.REPORTS,
    Component: lazy(() => import('pages/reports/reportList')),
    exact: true,
  },
  {
    ...APP.INVOICE_VIEW,
    Component: lazy(() => import('pages/invoices/invoiceView')),
    exact: true,
  },
  {
    ...APP.LEDGERS,
    Component: lazy(() => import('pages/ledgers/ledgerList')),
    exact: true,
  },
  {
    ...APP.LEDGER_ITEMS,
    Component: lazy(() => import('pages/ledgers/ledgerItem')),
    exact: true,
  },
  {
    ...APP.REPORT_VIEW,
    Component: lazy(() => import('pages/reports/reportView')),
    exact: true,
  },
  {
    ...APP.REFUNDS,
    Component: lazy(() => import('pages/refunds/refundList')),
    exact: true,
  },
  {
    ...APP.POST_VIEW,
    Component: lazy(() => import('pages/posts/postView')),
    exact: true,
  },
  {
    ...APP.ACTIVITY_LOGS,
    Component: lazy(() => import('pages/activityLogs')),
    exact: true,
  },

  {
    ...APP.MY_PROFILE,
    Component: lazy(() => import('pages/myProfile')),
    exact: true,
  },
  {
    ...APP.LEDGER_ITEM_VIEW,
    Component: lazy(() => import('pages/ledgers/ledgerItem/ledgerItemView')),
    exact: true,
  },
  {
    ...APP.NOT_FOUND,
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    ...APP.SOMETHING_WENT_WRONG,
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const RouterComponent = ({ history, routerAnimation }) => {
  return (
    <Router history={history}>
      <Layout>
        <Route
          render={(state) => {
            const {
              history: { location },
            } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {/* VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT */}
                    {routes.map(({ path, Component, exact, requiredScope }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => (
                          <div className={routerAnimation}>
                            <Suspense fallback={null}>
                              <Authorizer
                                Component={Component}
                                requiredScope={requiredScope}
                                {...state}
                              />
                            </Suspense>
                          </div>
                        )}
                      />
                    ))}
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    <Redirect to="/page-not-found" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </Router>
  )
}

export default connect(mapStateToProps)(RouterComponent)
