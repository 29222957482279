import { all, takeEvery, put } from 'redux-saga/effects'

import { doApiCall } from 'redux/helpers'
import LedgerService from 'services/ledger'
import actions from './actions'

const service = new LedgerService()

export function* FETCH_LEDGER_LIST({ payload: { query } }) {
  const response = yield doApiCall(service.getAllLedger, { query }, actions.SET_STATE)

  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { ledgerList: response.data, pagination: response.meta.pagination },
    })
  }
}
export function* FETCH_LEDGER_ITEMS_LIST({ payload: { query, path } }) {
  const response = yield doApiCall(service.getAllLedgerItems, { query, path }, actions.SET_STATE)

  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { ledgerItemsList: response.data, pagination: response.meta.pagination },
    })
  }
}
export function* FETCH_SINGLE_LEDGER({ payload: { path } }) {
  const response = yield doApiCall(service.getSingleLedger, { path }, actions.SET_STATE)
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { detail: response.data },
    })
  }
}
export function* FETCH_SINGLE_LEDGER_ITEM({ payload: { path } }) {
  const response = yield doApiCall(service.getSingleLedgerItem, { path }, actions.SET_STATE)
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { ledgerItemDetail: response.data },
    })
  }
}
export function* RETRY_LEDGER_SETTLEMENT({ payload: { path } }) {
  const response = yield doApiCall(service.retryLedgerSettlement, { path }, actions.SET_STATE)
  if (response.success) {
    yield put({
      type: actions.FETCH_SINGLE_LEDGER,
      payload: { path },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_LEDGER_ITEMS_LIST, FETCH_LEDGER_ITEMS_LIST),
    takeEvery(actions.FETCH_SINGLE_LEDGER_ITEM, FETCH_SINGLE_LEDGER_ITEM),
    takeEvery(actions.FETCH_SINGLE_LEDGER, FETCH_SINGLE_LEDGER),
    takeEvery(actions.FETCH_LEDGER_LIST, FETCH_LEDGER_LIST),
    takeEvery(actions.RETRY_LEDGER_SETTLEMENT, RETRY_LEDGER_SETTLEMENT),
  ])
}
