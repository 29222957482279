import * as qs from 'querystring'
import apiClient from 'services/axios'
import APIs from './constants'

class CommentService {
  constructor() {
    this.client = apiClient
  }

  getAllComments = async ({ query }) => {
    return this.client.get(`${APIs.COMMENTS_ALL}?${qs.stringify(query)}`)
  }

  deleteComment = async ({ path }) => {
    return this.client.delete(`${APIs.SINGLE_COMMENT.replace(':id', path?.commentId)}`)
  }

  //   getSingle = async ({ path }) => {
  //     return this.client.get(`${APIs.SINGLE_POST.replace(':id', path.id)}`)
  //   }

  //   performActionPost = async ({ body, path }) => {
  //     console.log('{+++++===]', path)
  //     return this.client.post(`${APIs.PERFORM_ACTION_POST.replace(':id', path.id)}`, body)
  //   }
}

export default CommentService
