// import { all, takeEvery, put } from 'redux-saga/effects'
import { all, takeEvery, put } from 'redux-saga/effects'

// import { notification } from 'antd'
// import { history } from 'index'
import { doApiCall } from 'redux/helpers'
import RefundService from 'services/refund'
// import APP from 'routes'
import actions from './actions'

const service = new RefundService()

export function* FETCH_REFUNDS({ payload: { query } }) {
  const response = yield doApiCall(service.getAll, { query }, actions.SET_STATE)

  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { list: response.data, pagination: response.meta.pagination },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_REFUNDS, FETCH_REFUNDS)])
}
