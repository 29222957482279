import * as qs from 'querystring'
import apiClient from 'services/axios'
import APIs from './constants'

class AnalyticsService {
  constructor() {
    this.client = apiClient
  }

  getAnalyticsPosts = async ({ path, query }) => {
    return this.client.get(
      `${APIs.ANALYTICS_POSTS.replace('/:id/:alias', `/${path?.id}/${path?.alias}`)}?${qs.stringify(
        query,
      )}`,
    )
  }

  getAnalyticsPublications = async ({ path, query }) => {
    return this.client.get(
      `${APIs.ANALYTICS_PUBLICATIONS.replace(
        '/:id/:alias',
        `/${path?.id}/${path?.alias}`,
      )}?${qs.stringify(query)}`,
    )
  }

  getAnalyticsRevenue = async ({ query }) => {
    return this.client.get(`${APIs.ANALYTICS_REVENUE}?${qs.stringify(query)}`)
  }

  getAnalyticsDashbordStatstics = async () => {
    return this.client.get(`${APIs.ANALYTICS_LAST_MONTH_STATS}`)
  }

  //   getAnalyticsPublications = async ({ body }) => {
  //     return this.client.post(`${APIs.ADMIN_SETTINGS}`, body)
  //   }
}

export default AnalyticsService
